import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThinkHowLogoWhite from '../assets/svg/thinkHowWhite.inline.svg'
import { below } from '../utils/media-query'

const SiteNavFooterPanel = styled.div`
  background: #020202;
  opacity: 0.9;
`
const FooterContainer = styled.div`
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;

  ${below.small`
    flex-wrap: wrap;
    text-align: center;
    padding: 0 1rem;
  `}
`
const SiteInfo = styled.div`
  padding: 3rem;
  padding-left: 0;
  width: 50%;

  ${below.med`
    padding-right:0;
  `}

  ${below.small`
    width: 100%;
    padding: 2rem 0;
  `}
`

const CompanyTrivia = styled.div`
  max-width: 350px;

  ${below.small`
    margin: 0 auto;
  `}
`

const TriviaCopy = styled.p`
  color: #ffffff;
  opacity: 0.8;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #ffffff;
  
  a{
    text-decoration: underline;
  }
`

const SiteNavLinks = styled.div`
  padding: 3.75rem;
  padding-right: 0px;

  ${below.med`
    padding-left:0;
  `}

  ${below.small`
    width: 100%;
    padding: 2rem 0;
  `}
`

const SiteNavLink = styled.div`
  opacity: 0.8;
  padding: 0 3.75rem;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  border-right: 1px solid rgba(255, 255, 255, 0.8);
  display: inline;
  
  a{
    color: #ffffff;
  }
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
  ${below.small`
    padding: 0 22px;
    font-size: 18px;
    &:first-of-type {
      padding-left: 0;
    }
  `}
`

const CopyrightFooterPanel = styled.div`
  background: #020202;
  opacity: 0.81 !important;
  height: 2.5rem;

  ${below.med`
    height: auto;
    padding: 10px 0;
  `}
  ${below.small`
    padding: 0;
  `}
`

const CopyrightContainer = styled(FooterContainer)`
  height: 100%;
  align-items: center;
  ${below.med`
    flex-direction: column;
    row-gap: 7px;
  `}
  ${below.small`
    flex-direction: unset;
    row-gap: unset;
  `}
`

const Copyright = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  color: #ffffff;
  opacity: 0.3;
  font-size: 12px;
  font-weight: light;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 0;

  ${below.small`
    order: 2;
    width: 100%;
    padding-bottom: 0.438rem;
  `}
`

const LegalContentLinks = styled.div`
  ${below.small`
    padding-top:  0.688rem;
    padding-bottom: 1.2rem;
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`

const LegalContentLink = styled.div`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  font-size: 14px;
  padding: 0 1.25rem;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: inline;
  
  a{
    opacity: 0.3;
    color: #fff;
  }
  &:last-child {
    padding-right: 0;
    border-right: 0;
  }

  ${below.small`
    font-size: 11px;
    opacity: 0.8;
  `}
`
const Logo = styled(ThinkHowLogoWhite)`
  width: 100%;
  max-width: 148px;
  padding: 0;
  margin: 0 0 1.45rem;
`

const Footer = () => (
  <div>
    <SiteNavFooterPanel>
      <FooterContainer>
        <SiteInfo>
          <Link to='/' title='Logo'>
            <Logo />
          </Link>
          <CompanyTrivia>
            <TriviaCopy>
              To inquire about advertising on ThinkHow, contact{' '}
              <a href='mailto:Advertising@thinkhow.com'>
                Advertising@thinkhow.com
              </a>
            </TriviaCopy>
          </CompanyTrivia>
        </SiteInfo>
        <SiteNavLinks>
          <SiteNavLink>
            <Link to='/about'>About</Link>
          </SiteNavLink>
          <SiteNavLink>
            <Link to='/contact'>Contact</Link>
          </SiteNavLink>
        </SiteNavLinks>
      </FooterContainer>
    </SiteNavFooterPanel>
    <CopyrightFooterPanel>
      <CopyrightContainer>
        <Copyright>@ 2021. THINKHOW. All Rights Reserved</Copyright>
        <LegalContentLinks>
          <LegalContentLink>
            <Link to='/info/terms-of-use'>Terms of Use</Link>
          </LegalContentLink>
          <LegalContentLink>
            <Link to='/info/privacy-policy'>Privacy Policy</Link>
          </LegalContentLink>
          <LegalContentLink>
            <Link to='/info/marketing-disclosure-policy'>
              Marketing Disclosure Policy
            </Link>
          </LegalContentLink>
        </LegalContentLinks>
      </CopyrightContainer>
    </CopyrightFooterPanel>
  </div>
)

export default Footer
