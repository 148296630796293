import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const LayoutHelmet = ({ adsense }) => {
  return (
    <StaticQuery
      query={graphql`
        query siteQuery {
          site {
            ...SiteInformation
          }
        }
      `}
      render={data => (
        <Helmet>
          <html lang='en' />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name='description'
            content={data.site.siteMetadata.description}
          />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/img/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            href='/img/favicon-32x32.png'
            sizes='32x32'
          />
          <link
            rel='icon'
            type='image/png'
            href='/img/favicon-16x16.png'
            sizes='16x16'
          />
          <link rel='dns-prefetch' href='//fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
          <link
            rel='preload'
            as='style'
            href='https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap'
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap'
            media='print'
            onLoad='this.media="all"'
          />
          <link
            rel='preload'
            as='style'
            href='https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap'
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap'
            media='print'
            onLoad='this.media="all"'
          />
          <link
            rel='preload'
            as='style'
            href='https://fonts.googleapis.com/css2?family=Lato&display=swap'
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css2?family=Lato&display=swap'
            media='print'
            onLoad='this.media="all"'
          />
          <link rel='mask-icon' href='/img/favicon-32x32.png' color='#ff4400' />
          <meta name='theme-color' content='#fff' />
          <meta property='og:type' content='business.business' />
          <meta property='og:title' content={data.site.siteMetadata.title} />
          <meta property='og:url' content='/' />
          <meta property='og:image' content='/img/og-image.jpg' />
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=G-HR61LHP51X'
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments)};
              gtag('js', new Date());

              gtag('config', 'G-HR61LHP51X');`}
          </script>
          {adsense && (
            <script
              async
              src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
            />)}
          {adsense && (
            <script>
              {`
                (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-6867008335629681",
                enable_page_level_ads: true
                });`}
            </script>)}
        </Helmet>
      )}
    />
  )
}

export default LayoutHelmet
