import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import HamburgerIcon from '../assets/svg/hamburger.inline.svg'

import styled from '@emotion/styled'
import ThinkHowLogo from '../assets/svg/thinkHowBlack.inline.svg'
import { below } from '../utils/media-query'

const ToggleMenu = css`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #000;
    font-size: 1.1rem;
    svg {
      transition: color 0.2s ease, border-bottom-color 0.2s ease;
    }
  }
  margin-left: auto;
`

const ToggleMenuContainer = styled.section`
  transition: visibility ease 0.5s, opacity 0.5s ease, transform 0.5s ease;
  background: #ffffff;
  position: fixed;
  box-shadow: none;
  width: 100%;
  overflow-y: auto;
  left: 0;
  top: -100vh;
  z-index: 9;
  visibility: ${(props) => (props.showMenu ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.showMenu ? '1' : '0.9')};
  transform: ${(props) => (props.showMenu ? 'translateY(100vh)' : 'none')};
  ${below.small`
    top: -100vh;
    `}
  section {
    padding: 3em 0 0 0;
    ${below.small`
      padding: 5em 0 0 0;
      width: 100vw;
    `}
  }
  ul {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 75.375rem;
    list-style-type: none;
    margin: 0 auto;
    padding-right: 3em;
    ${below.small`
      height: 100vh;
      overflow-y: scroll;
      padding-right: 1rem;
    `}
  }
  li {
    border: 0;
    margin-bottom: 12px;
    ${below.small`
      margin: 6px 0 6px 64px;
    `}
    :first-of-type {
      border-top: 0;
      margin-top: 24px;
      padding-top: 0;
    }
  }
  li a {
    text-decoration: none;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2rem;
  }
  h3 {
    transition: color 0.2s ease;
    font-size: 30px;
    font-family: "Josefin Sans", Helvetica, sans-serif;
    letter-spacing: normal;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #181a1b;
    opacity: 0.8;
    margin-bottom: 0;
    ${below.small`
      font-size: 24px !important;
      margin: 0;
    `}

    :hover {
      color: #185f7a !important;
    }
  }
`

const NavWrapper = styled.nav`
  display: flex;
  background: #ffffff;
  border-bottom: ${(props) =>
    props.showMenu
      ? '0px solid rgba(160, 160, 160, 0.3)'
      : '1px solid rgba(160, 160, 160, 0.3)'};
  height: 4.1rem;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0px;
  left: 0px;
  margin: 0 auto;


  a {
    font-weight: 800;
    font-size: 0.8125rem;
    text-transform: uppercase;
    letter-spacing: 4.5px;
    color: #3c3b3b;
    font-family: Raleway, sans-serif;
  }
  ${below.small`
    height: inherit;
    width: calc(100vw);
  `}
`

const HamburgerMenu = styled.a`
  cursor: pointer;
  padding-right: 3rem;
  ${below.med`
    padding-right: 1.5rem;
  `}
  ${below.small`
      padding-right: 12px;
  `}
`

const NavTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  ${below.med`
    padding-left: 1.5rem;
  `}
  ${below.small`
    padding: 1rem;
  `}
`

const NavLinks = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 75.375rem;
  align-items: center;
  z-index: 10003;

  ${below.med`
  `}

  ul {
    display: flex;
    list-style: none;
  }

  img,
  ul,
  li {
    margin: 0;
  }

  li {
    padding: 0 1.4rem;
    border-left: 1px solid rgba(160, 160, 160, 0.3);
    display: flex;
    align-items: center;
    z-index: 10;
  }

  li:first-of-type {
    border-left: 0;
    margin: 0;
    padding-left: 1.5rem;
    a {
      color: #646464;
    }
    .active {
      color: #2ebaae;
      font-weight: 800;
    }
  }

  a {
    font-weight: 400;
  }

  ${below.large`
    li {
      padding: 0 0.6rem;
    }
    ul {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    `}
`
const Logo = styled(ThinkHowLogo)`
  width: 100%;
  max-width: 148px;
  ${below.small`
    width: auto;
  `}
  ${below.small`
    height: 14px;
  `}
`
const LogoShadow = styled(ThinkHowLogo)`
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-80%);
  width: 70%;
  opacity: 0.02;
  z-index: 0;

  ${below.small`
    width: 90%;
    top: unset;
    bottom: 1rem;
    left: 50%;
    right: 0;
    transform: translate(-50%, -100%);
  `}
`

const Navbar = props => {
  return (
    <>
      <NavWrapper
        ref={props.navRef}
        role='navigation'
        aria-label='main-navigation'
        showMenu={props.showMenu}
      >
        <NavLinks>
          <NavTitle>
            <Link to='/' title='Logo' ref={props.logoRef}>
              <Logo />
            </Link>
          </NavTitle>
          <div css={ToggleMenu}>
            <HamburgerMenu
              data-testid='toggle-icon'
              onClick={(e) => {
                e.preventDefault()
                props.toggleMenu()
              }}
            >
              <HamburgerIcon style={{ width: '20px', height: '20px' }} />
            </HamburgerMenu>
          </div>
        </NavLinks>
      </NavWrapper>
      <ToggleMenuContainer
        ref={props.menuRef}
        id='menu'
        data-testid='template-sidebar'
        showMenu={props.showMenu}
      >
        <section>
          <ul className='links'>
            {props.categoriesList.map((category, index) => (
              <li key={index}>
                <Link to={category?.slug} onClick={() => props.toggleMenu()}>
                  <h3>{category?.title}</h3>
                </Link>
              </li>
            )
            )}
            <LogoShadow />
          </ul>
        </section>
      </ToggleMenuContainer>
    </>
  )
}

export default Navbar
