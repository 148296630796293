import React, { Component } from 'react'
import LayoutHelmet from './helmet/LayoutHelmet'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'

import './layout.css'

import Navbar from '../components/Navbar'
import { below } from '../utils/media-query'
import Footer from './Footer'
import { PermissionPopup } from './PermissionPopup'

const AppWrapper = styled.div`
  min-height: 100vh;
  font-family: 'Liberation Sans', Helvetica, sans-serif;
  background: #FFFFFF;
  ${props => (!props.offerPage
  ? 'padding-top: 4.1rem;'
  : null)}
  ${props => (props.IsPageLayout
  ? 'padding-top: 4.1rem; // height of nav-bar'
  : null)}
  a {
    color: #646464;
    text-decoration: none;
    transition: color 0.2s ease, border-bottom-color 0.2s ease;
    :hover {
      color: #185f7a;
    }
  }
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${below.small` padding-top: 3.1rem;`}
`

const BodyContainer = styled.div``

const BodyWrapper = styled.div`
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: ${(props) => (props.showMenu ? '0.15' : '1')};
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 0;
  margin: 0 auto;
  color: #646464;
  max-width: 100rem;
  pointer-events: ${(props) => (props.showMenu ? 'none' : 'unset')};
   footer {
     margin-top: 3rem;
     align-items: start;
   }
  ${below.med`
    padding: 0;
    min-height: 100vh;
  `}
  ${({ props }) =>
  props.IsBlogPage
    ? `
    padding: 2.5rem 3rem 3rem 3rem;
    margin: 0 auto;
    color: #646464;
    max-width: 75.375rem;`
    : null}
    ${below.large`
      display: block;
    `}
    ${({ props }) => props.IsPageLayout ? 'padding-top: 3rem;' : null}
`

class TemplateWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = { showMenu: false }
    this.menuRef = React.createRef(null)
    this.navRef = React.createRef(null)
    this.logoRef = React.createRef(null)
  }

  componentDidUpdate (prevState) {
    if (this.state.showMenu !== prevState.showMenu) {
      document.documentElement.style.overflowY =
      !this.state.showMenu ? 'scroll' : 'hidden'
    }
  }

  componentWillUnmount () {
    document.documentElement.style.overflowY = 'scroll'
  }

  toggleMenu () {
    this.setState({ showMenu: !this.state.showMenu })
  }

  clickOutsideHandle (e) {
    const click = e.target
    const menuIsOpen = this.state.showMenu
    const menu = this.menuRef?.current
    const nav = this.navRef?.current
    const logo = this.logoRef?.current

    if (!menuIsOpen || !menu || !logo || !nav) return

    const menuClicked = menu.contains(click)
    const navClicked = nav.contains(click)
    const logoClicked = logo.contains(click)

    if ((!menuClicked && !navClicked) || logoClicked) {
      this.setState({ showMenu: !this.state.showMenu })
    }
  }

  render () {
    // eslint-disable-next-line
    const { children, data, IsPageLayout } = this.props
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "category-page" } } }
            ) {
              nodes {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
      `}
        render={data => {
          const { removeFooter, offerPage, adsense } = this.props
          const { allMarkdownRemark } = data
          const categoriesList = allMarkdownRemark.nodes.map(category => ({
            title: category?.frontmatter?.title,
            slug: category?.fields?.slug
          }))
          return (
            <>
              <LayoutHelmet adsense={adsense} />
              <div id='contentWrapper'>
                <AppWrapper
                  onClick={e => this.clickOutsideHandle(e)}
                  offerPage={offerPage}
                  IsPageLayout={IsPageLayout}
                >
                  <BodyContainer showMenu={this.state.showMenu}>
                    <Navbar
                      navRef={this.navRef}
                      logoRef={this.logoRef}
                      menuRef={this.menuRef}
                      toggleMenu={this.toggleMenu.bind(this)}
                      showMenu={this.state.showMenu}
                      categoriesList={categoriesList}
                    />
                    <BodyWrapper
                      props={this.props}
                      showMenu={this.state.showMenu}
                      onClick={(e) => this.clickOutsideHandle(e)}
                    >
                      {children}
                    </BodyWrapper>
                  </BodyContainer>
                  <Footer
                    removeFooter={removeFooter}
                    offerPage={offerPage}
                    IsPageLayout={IsPageLayout}
                  />
                </AppWrapper>
                <PermissionPopup />
              </div>
            </>
          )
        }}
      />
    )
  }
}

export default TemplateWrapper
