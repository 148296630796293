import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { above, below } from './utils/media-query'
import DownArrow from './assets/svg/arrow.svg'

export const BlogBody = styled.section`
  border-top: solid 1px rgba(160, 160, 160, 0.3);
  a {
    color: #3C3B3B;
  }
  padding: 1.5rem;
  ${above.small`
    padding: 3rem;
  `}
`

const contentFontFamily = '\'Liberation Sans\', Helvetica, sans-serif'
export const BlogContent = styled.div`
  font-family: 'Liberation Sans', Helvetica, sans-serif;
  h2 {
    text-align: left;
  }
  h1,
  h2,
  h3 {
    font-family: 'Liberation Sans', Helvetica, sans-serif;
  }
  a {
    text-decoration: underline;
  }
  .disclaimer {
    font-size: 80%;
    font-weight: bold;
    font-family: serif;
  }
  .offer__button {
    text-decoration: none;
    color: white;
  }
  .offer__button:hover {
    color: white;
  }
  .multi-offer__list {
    width: 100%;
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .multi-offer__btn {
    text-decoration: none;
    color: #f6f6f6;
    background-color: #2ebaae;
    transition: all 0.3s ease;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 0.0125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.125rem;
    border-radius: 0.5rem;
    text-shadow: 0 1px 1px #104f33;
    height: 4rem;
  }
  .multi-offer__btn:hover{
    color: #fff;
    background-color: rgba(46, 186, 174, 0.71);
    transition: all 0.3s ease;
  }
  .chevron {
    border-right: 0.125rem solid #fff;
    border-bottom: 0.125rem solid #fff;
    width: 0.625rem;
    height: 0.625rem;
    transform: rotate(-45deg);
  }
  .multi-offer li {
      width: inherit;
      margin: 0.7% 0.5%;
      display: flex;
      justify-content: center;
      flex-direction: column;
  }

  /** lincx Ad Style START */
  .offersTarget {
    .offers {
      padding-left: 0;

      .offers__header {
        font-family: ${contentFontFamily} !important;
        font-size: 1rem !important;
        font-weight: normal;
      }
      .offers__list {
        margin-left: 0 !important;

        .offer__link, .offer__button {
          font-family: ${contentFontFamily} !important;
          font-size: 1rem !important;
        }
        .offer__content {
          .offer__headline{
            font-family: ${contentFontFamily} !important;
            font-size: 1rem !important;
          }
        }
      }
    }
  }
  /** lincx Ad Style END */

  ${above.small`
    .multi-offer__list {
      justify-content: space-around;
    }
    .multi-offer li {
      flex-basis: calc(50% - 40px);
    }
    .multi-offer__btn {
      font-size: 1rem
    }
    .multi-offer {
      padding-left: 1rem;
    }
  `}
  ${above.large`
    .multi-offer__btn {
      font-size: 1.25rem
    }
  `}
  ${below.small`
    .multi-offer {
      padding-right: 1.5rem;
    }
    .multi-offer__btn {
      height: 3rem;
    }
  `}

`

export const BlogWrapper = styled.article`
  grid-area: content;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  background: #fff;
  border: solid 1px rgba(160, 160, 160, 0.3);
  margin: 0 0 3rem 0;

  ${BlogBody} {
    h1 {
      font-size: 1rem;
      font-weight: 800;
    }

    h2 {
      font-size: 1.1rem;
    }

    h4 {
      font-size: 0.7rem;
      font-weight: 800;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin: 0 0 2em 0;
      font-weight: 400;
    }
    ${below.small`
      p {
        margin: 0 0 1em 0;
      }
    `}
  }
  ${below.small`
    h2 {
      padding: 0;
      font-size: 100%;
    }
  `}
`

export const BlogTitle = styled.div`
  padding: 3.75rem 2.5rem 3.25rem 3rem;
  flex-grow: 1;

  h1 {
    font-size: 1.75rem;
    margin: 0;
  }

  h1:hover {
    color: #2ebaae;
  }

  h2 {
    font-family: 'Liberation Sans', Helvetica, sans-serif;
    margin: 0;
    letter-spacing: 0.25rem;
    padding: 0;
    font-size: 1.5rem;
  }

  ${below.med`
    padding: 0;
    margin-bottom: 2rem;

    h1 {
      text-align: center;
    }
  `}
  ${below.small`
    margin-bottom: 0rem;
    h2 {
      padding: 0;
      font-size: 100%;
    }
  `}
`

export const Banner = styled.div`
  margin-bottom: 3rem;

  a {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    color: #3c3b3b;
  }
  ${below.small`
    margin: 0rem -1.5rem 1.5rem;
  `}
`

export const offerButton = css`
  .offerButton[role="adverse"]  {
      text-decoration: none;
      background-color: rgb(46, 186, 174);
      margin: 0 auto 2rem;
      box-shadow: rgba(160, 160, 160, 0.3) 0px 0px 0px 1px inset;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: block;
      font-family: Raleway, Helvetica, sans-serif;
      font-size: 0.7rem;
      font-weight: 800;
      height: 3.3125rem;
      letter-spacing: 0.175rem;
      line-height: 3.3125rem;
      padding: 0 2.0625rem;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease;
      width: fit-content;
      -webkit-appearance: none;
      :hover {
        color: white;
        opacity: 0.7;
        transition: all 0.3s ease;
      }
      ${below.tiny`
        font-size: 0.6rem;
        width: inherit
      `}
  }
`
export const PopupContainer = styled.div`
  width: 400px;
  background: #fff;
  box-shadow: 10px 10px 40px 5px rgba(222,222,222,0.75);
  padding: 1rem;
  position: fixed;
  top: 20px;
  left: calc(50% - 200px);
  z-index:10;
  display: none;

  .permission__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .permission__brand-img {
      width: 100px;
      display: flex;
      justify-content: center;
    }
    .permission__details {
      .permission__main-contain {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .permission__info-content {
        font-size: 0.75rem;
      }
    }
  }
  .permission__action {
     display: flex;
     width: 100%;
     justify-content: center;
     .permission__action-btn {
      margin: 10px;
      color: #fff;
      padding: 0.51rem 1rem;
      width: auto;
      border: none;
      margin: 0 10px;
      white-space: normal;
      outline: none;
      font-weight: 600;
      cursor:pointer;
     }
    .permission__action-btn.action-allow {
      background: #444;
      &:hover {
        box-shadow: inset 0 0 0 1px #2ebaae;
        }
    }
    .permission__action-btn.action-close {
      background: #aaa;
    }
  }

  ${below.tiny`
    width: 90%;
    left: 5%;
    .permission__content {
      .permission__details {
        .permission__main-contain {
          font-size: 0.75rem;
          font-weight: 500;
        }
        .permission__info-content {
          font-size: 0.75rem;
        }
      }
    }
    .permission__action {
      .permission__action-btn {
        padding: 0.25rem 0.5rem
      }
    }
  `}
`

// Article Styles

export const FeaturedArticleBanner = styled(Link)`
  display: flex;
  width: 100%;
  height: 473px;
  background-color: #ccc;
  position: relative;

  .gatsby-image-wrapper{
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
  }
  ${below.small`
    height: 277px;
  `}
`
export const FeaturedArticleCopy = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  width: 100%;
  max-width: 75.375rem;
  margin: 0 auto;
  padding: 3rem;
  z-index: 1;
  p {
    margin-bottom: 10px;
  }
  ${below.med`
    padding: 1.5rem;
    max-width: 768px;
    margin: 0;
  `}
  ${below.small`
    padding: 1rem;
    max-width: 75.375rem;
    margin: 0 auto;
    p {
      margin-bottom: 5px;
    }
  `}
`
export const FeaturedArticleTitle = styled.h1`
  color: #181a1b !important;
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.87 !important;
  letter-spacing: -0.5px !important;
  align-self: self-start;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  span{
    margin-bottom: 10px;
    background-color: #fff;
    padding: 16px 7px;
    word-break: keep-all;
    ${below.small`
      margin-bottom: 5px;
      padding: 4px 5px;
    `}
  }
  ${above.large`
    max-width: 85%;
  `}
  ${below.med`
    font-size: 35px;
  `}
  ${below.small`
    font-size: 24px;
  `}
`
export const CategoryHighlight = styled.p`
  background-color: #ffab32;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  padding: 7px 16px;
  align-self: self-start;
  ${below.small`
    font-size: 12px;
  `}
`
export const FullWidthFeatureArticle = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 69.375rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  min-height: ${64 + 473}px;
  background-color: #ccc;
  margin-bottom: 2.5rem;
  position: relative;

  .gatsby-image-wrapper{
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
  }
  ${below.med`
    min-height: 350px;
  `}
  ${below.small`
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    min-height: 206px;
  `}
`
export const FullWidthFeatureArticleCopy = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  max-width: 60rem;
  margin: 0 auto;
  padding: 3rem;
  padding-bottom: 0;
  z-index: 1;
  p {
    margin-bottom: 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ${below.small`
    padding: 0;
  `}
`
export const FullWidthFeatureArticleTitle = styled(Link)`
  background-color: #fff;
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.5px;
  text-align: center;
  color: #181a1b !important;
  padding: 15px 115px;
  &:hover {
    color: #185f7a !important;
  }
  ${below.med`
    font-size: 24px;
    padding: 10px 50px;
  `}
  ${below.small`
    font-size: 16px;
    padding: 6px 8px;
  `}
`
export const ColoredCategoryHighlight = styled.p`
  background-color: ${props => (props.color ? props.color : '#34da90')};
  font-family: "Lato", Helvetica, sans-serif;
  font-size: ${props => (props.size ? props.size : '20')}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  padding: 7px 16px;
  align-self: self-start;
  margin-bottom: 10px;
  ${below.med`
    font-size: 16px;
    padding: 7px 12px;
  `}
  ${below.small`
    padding: 6px 8px;
    font-size: 12px;
    margin-bottom: 6px;
  `}
`
export const TwoColumnPanel = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  ${below.med`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    padding: 0 1rem;
    padding-top: 2rem;
    flex-wrap: wrap;
  `}
`
export const TwoColourBackgroundContainer = styled.div`
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    to ${props => props.reverse ? 'left' : 'right'},
    #f8f8f8 0%,
    #f8f8f8 68.75%,
    ${props => props.expanded ? '#f8f8f8' : 'white'} 68.75%,
    ${props => props.expanded ? '#f8f8f8' : 'white'} 100%
  );
  ${below.small`
    background: #f8f8f8;
  `}
`
export const TwoColourBackgoundPanel = styled.div`
  min-height: 640px;
  margin-top: 2.5rem;
  background: linear-gradient(
    to ${props => props.reverse ? 'left' : 'right'},
    #f8f8f8 0%,
    #f8f8f8 50%,
    ${props => props.expanded ? '#f8f8f8' : 'white'} 50%,
    ${props => props.expanded ? '#f8f8f8' : 'white'} 100%
  );
  ${below.med`
    min-height: unset;
  `}
  ${below.small`
    min-height: 640px;
    margin-top: 1.5rem;
    background: #f8f8f8;
  `}
`

// About and Contact Pages
export const BannerContainer = styled.div`
  width: 100%;
  max-height: 320px;
  height: 320px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .gatsby-image-wrapper{
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
  }
  ${below.small`
    height: 143px;
  `}

  div:first-of-type {
    max-width: 920px;
    height: 100%;
    margin: 0 auto;
    position: relative;

    h1 {
      margin: auto auto 0 auto;
      font-family: "Josefin Sans",Helvetica,sans-serif;
      font-size: 3.75rem;
      color: #181a1b;
      line-height: 0.87;
      letter-spacing: -0.5px;
      background-color: #fff;
      padding: 1.18rem 1rem 0.5rem 1rem;
      position: absolute;
      bottom: 2.93rem;
      left: 0;
      z-index: 1;

      ${below.med`
        left: 1.25rem;
      `}
      ${below.small`
        font-size: 1.5rem;
        padding: 0.6rem 0.5rem 0.25rem 0.5rem;
        left: 1.25rem;
        bottom: 1.68rem;
      `}
    }
  }
`
export const AboutContactContainer = styled.section`
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding-top: 2.5rem;
  min-height: 427px;

    p{
      font-family: "Lato",Helvetica,sans-serif;
      color: #181a1b;
      opacity: 0.8;
      font-size: 1.125rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.56;
      margin-bottom: 0;
      &:not(:last-child){
        margin-bottom: 0.75rem;
      }
    }

  ${below.med`
    p{
      font-size: 16px;
    }
    padding: 1.25rem;
    padding-bottom: 2.5rem;
  `}
  ${below.small`
    p{
      font-size: 0.875rem;
    }
  `}
`

// Resources styles
export const Resources = styled.div`
  margin-top: 3rem;
  h3 {
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #181a1b;
  }
  li{
    font-size: 16px;
    a {
      color: #0645AD;
      font-family: "Lato",Helvetica,sans-serif;
      font-weight: 400;
    }
    ${below.small`
      font-size: 14px;
    `}
  }
`
// Info page styles
export const InfoPageContainer = styled.section`
  max-width: 920px;
  margin: 0 auto;

  h1, h2{
    font-family: "Josefin Sans", Helvetica, sans-serif;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.5px;
    color: #181a1b;
    text-transform: unset;
  }

  p, strong{
    opacity: 0.8;
    font-family: "Lato",Helvetica,sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #181a1b;
  }

  strong{
    font-weight: bold;
    color: #000;
  }

  h2{
    font-size: 26px;
    margin-top: 3rem;
    text-align: left;
  }

  ${below.med`
    h1{
      font-size: 45px;
    }
    h2{
      font-size: 18px;
      margin-top: 1rem;
    }
    p, strong{
      font-size: 16px;
    }
  `}
  ${below.small`
    h1{
      font-size: 24px;
    }
    h2{
      font-size: 16px;
    }
    p, strong{
      font-size: 14px;
    }
  `}
`
export const ReadMoreableFeatureArticleContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  padding-right: 15px;
  width: 100%;
  margin: 0 auto;
  max-width: 75.375rem;
  padding-left: 3rem;
  padding-right: 3rem;
  ${below.small`
    padding: 0 1rem;
    padding-top: 1rem;
    flex-wrap: wrap;
  `}
`
export const ArticlePreviewImage = styled(Img)`
  width: 50%;
  height: 34.125rem;
  ${below.small`
    width: 100%;
    height: 288px;
  `}
  ${props => props.reverse ? 'left' : 'right'}
`
export const ReadMoreableFeatureArticleCopy = styled.div`
  width: ${(props) => (props.expanded ? '100%' : '50%')};
  align-self: self-end;
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.expanded ? 'none' : '#ffffff')} !important;
  min-height: 455px;
  ${({ reverse, expanded }) => (!expanded && reverse
      ? 'margin-right: 15px;'
      : !expanded && !reverse
      ? 'margin-left: 15px;'
      : null

  )}
  ${({ extendable }) => (extendable
  ? `
    padding: 30px;
    padding-top: 50px;
  `
  : null)}
  ${below.small`
    width: 100%;
    margin: 0;
    margin-top: 8px;
    padding: 1rem;
    min-height: initial;
  `}
`
export const AlignDiv = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-self: self-${props => props.reverse ? 'end' : 'start'};
`
export const ArticleTitle = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.5px;
  color: #181a1b;
  margin-bottom: 10px;
  ${below.small`
    font-size: 16px;
    margin-bottom: 8px;
  `}
`
export const ArticleText = styled.p`
  font-family: "Lato", Helvetica, sans-serif;
  opacity: 0.8;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #181a1b;
  ${below.small`
    font-size: 14px;
  `}
`
export const ReadMoreButton = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 3.5px;
  color: #31505c;
  margin-top: 2.5rem;
  cursor: pointer;
  width: max-content;
  &:hover {
    color: #185f7a !important;
  }
  &::after {
    content: '';
    mask: url(${DownArrow});
    mask-size: cover;
    width: 9px;
    height: 9px;
    display: inline-block;
    background-color: currentColor;
    ${(props) => (props.expanded && `
      transform: rotate(-180deg);
    `)}
  }
`

export const previewHoverEffect = css`
  overflow: hidden;
  picture img, img, h2 {
    transition: all .5s ease !important;
  }

  &:hover{
    cursor: pointer;
    & picture img, img {
      transform: scale(1.03);
    }
    h2 {
      color: #185f7a !important;
    }
  }
`
